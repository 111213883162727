import { useState } from "react"; 
import './bootstrap.css';

function App() {

  const [value, setValue] = useState( '' );
  const [list, setList] = useState( [] );

  const handleOnChange = ( e ) => {
    setValue( e.target.value );
  }
  const handleOnClick = () => {
    setList([...list, value]);
    setValue ( "" );
  }
  const handleDelete = ( index ) => {
    const newList = list.filter( ( item, i ) => i !== index);
    setList ( newList );
  }

  return (
    <>
      <h1>Todo List </h1>
      <input 
      type="text" 
      value={value}
      onChange={ handleOnChange }
        placeholder="Introducir tarea" 
        />
        <p>{value}</p>
      <button onClick={ handleOnClick }>Agregar Tarea </button>
      <ul>
        {
        list.length > 0 
        ? list.map ((item, i ) => {
          return <li key ={ i }>
            { item } &nbsp;
            <button onClick = { () => handleDelete(i) }>Delete</button>
            </li>
          
        }) 
        :<p>no hay tareas </p>
        
        }
      </ul>
      
    </>
  );
}

export default App;

